/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1cc14ec5-8a93-4265-b28f-5a69a495cd02",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yAU2vfhNm",
    "aws_user_pools_web_client_id": "3sirkms22iaum5r3e0u8gjee44",
    "oauth": {}
};


export default awsmobile;
