export const academyLoginTheme = {
  formContainer: {
    'margin': '0'
  },
  formSection: {
    'background': 'none'
  },
  sectionHeader: {
    'fontSize': '35px',
    'color': '#fff',
    'fontWeight': 'bold',
  },
  toast: {
    'position': 'fixed',
    'background': '#cc2f48',
    'color': '#1c1c1c',
  },
  inputLabel: {
    'color': '#fff'
  }
};
