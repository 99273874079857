import React, { Component } from 'react'
import './App.css'
import './assets/fonts/montserrat/css/style.css'
import Amplify, { Auth, I18n } from 'aws-amplify'
import awsconfig from './aws-exports'
import { dict } from './vocabularies/disct'
import Authentication from './components/pages/Authentication'
import { clearCookie, createCookie, redirectTo, redirectToLogin } from './util/util'

Amplify.configure(awsconfig)
I18n.setLanguage('pt_BR')
I18n.putVocabularies(dict)

export default class App extends Component {
  constructor () {
    super()
    this.state = {
      authState: '',
      idToken: null,
      register: false
    }
  }

  async isAuth () {
    try {
      const response = await Auth.currentSession()
      createCookie(response)

      this.setState({
        authState: 'signedIn'
      })
    } catch (e) {
      clearCookie()
      this.setState({
        authState: 'signOut'
      })
    }
  }

  async componentDidMount () {
    await this.isAuth()
  }

  render () {
    const { authState } = this.state
    if (authState) {
      return (
        <RenderPage authState={this.state.register === true ? 'signUp' : authState} />
      )
    } else {
      return <div />
    }
  }
}

const getParamsValue = (text) => {
  try {
    console.log('teste')
    console.log(atob(text))
    return atob(text)
  } catch (e) {
    return ''
  }
}

window.onbeforeunload = function (e) {
  sessionStorage.getItem('lmsDemoEmail') && sessionStorage.removeItem('lmsDemoEmail')
}

const RenderPage = ({ authState }) => {
  const params = window.location.search.split(/[?=]+/)
  const params2 = params[2] ? params[2].split(/[&=]+/) : ''
  const redirect = (params2.length && params2[0] && params[1] !== 'code') ? getParamsValue(params2[0]) : ''

  const logout = () => {
    Auth.signOut().then(() => {
      clearCookie()
    }).catch(() => {
      clearCookie()
    })
    redirectToLogin(redirect)
  }

  const logoutRedirect = () => {
    Auth.signOut().then(() => {
      clearCookie()
    }).catch(() => {
      clearCookie()
    })
    redirectTo(redirect)
  }

  switch (params[1]) {
    case 'renew' :
      return (
        <Authentication page='renew' />
      )

    case 'logout' :
      logout()
      return <div />
    case 'logoutRedirect' :
      logoutRedirect()
      return <div />
    case 'signUp' :
      return (
        <Authentication page='signUp' />
      )
    default:
      if (authState === 'signOut') {
        return (
          <Authentication />
        )
      } else if (authState === 'signUp') {
        return (
          <Authentication page='signUp' />
        )
      } else if (authState === 'signedIn') {
        redirectTo(redirect)
      }

      return <div />
  }
}
