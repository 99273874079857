/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { I18n } from 'aws-amplify'
import { ForgotPassword } from 'aws-amplify-react'
import logo from '../../assets/images/logo.png'
import { settings } from '../../configs/settings'

export class CustomForgotPassword extends ForgotPassword {
  showComponent () {
    const { authData = {} } = this.props

    return (
      <div className='cardMiddle'>
        <div className='row'>
          <div className='col-6 image-align'>
            <div className='card-image'>
              <div className='col col-12 border-top'>
                <div className='title-logo'>
                  <img
                    src={logo}
                    alt={settings.title_site}
                    className='img-fluid logo'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-6 card-forgotpassword'>
            <div className='row'>
              <div className='col col-8 offset-2'>
                <div className='ResetPassword text-left'>
                  {this.state.delivery || authData.username
                    ? this.submitView()
                    : this.sendView()}
                  <div className='center'>
                    {this.state.delivery || authData.username ? (
                      <button
                        className='btn forgot-password'
                        onClick={this.submit}
                      >
                        {I18n.get('Submit')}
                      </button>
                    ) : (
                      <button className='btn' onClick={this.send}>
                        {I18n.get('Send Code')}
                      </button>
                    )}
                    {this.state.delivery || authData.username ? (
                      <button className='btn' onClick={this.send}>
                        {I18n.get('Resend Code')}
                      </button>
                    ) : (
                      <div>
                        <a
                          className='mb-30'
                          onClick={() => super.changeState('signIn')}
                        >
                          {I18n.get('Back to Sign In')}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
