/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { SignUp as SingUpBase } from 'aws-amplify-react'
import { I18n, Auth } from 'aws-amplify'
import PhoneField from 'aws-amplify-react/dist/aws-amplify-react'
import logo from '../../assets/images/logo.png'
import { settings } from '../../configs/settings'
import Cookie from 'js-cookie'
import { ConsoleLogger as Logger } from '@aws-amplify/core'

const logger = new Logger('SignUp')

export class SignUp extends SingUpBase {
  constructor (props) {
    super(props)
    this._validAuthStates = ['signUp']
  }

  signUp (e) {
    e.preventDefault()
    if (!this.inputs.dial_code) {
      this.inputs.dial_code = this.getDefaultDialCode()
    }
    const validation = this.validate()
    if (validation && validation.length > 0) {
      return this.error(
        `The following fields need to be filled out: ${validation.join(', ')}`
      )
    }
    if (!Auth || typeof Auth.signUp !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }

    const plataformOptions = document.getElementsByName('se_sim_qual_plataforma_ou_ferramenta_')[0]
    const plataform = plataformOptions.options[plataformOptions.selectedIndex].value || ''

    const signup_info = {
      username: this.inputs.username,
      password: this.inputs.password,
      attributes: {
        'custom:sell_online': document.getElementsByName('voce_vende_online_')[0].checked ? '1' : '0',
        'custom:sell_platform': plataform
      }
    }

    const inputKeys = Object.keys(this.inputs)
    const inputVals = Object.values(this.inputs)

    inputKeys.forEach((key, index) => {
      if (
        !['username', 'password', 'checkedValue', 'dial_code'].includes(key)
      ) {
        if (
          key !== 'phone_line_number' &&
          key !== 'dial_code' &&
          key !== 'error'
        ) {
          const newKey = `${this.needPrefix(key) ? 'custom:' : ''}${key}`
          signup_info.attributes[newKey] = inputVals[index]
        }
      }
    })

    if (this.phone_number) { signup_info.attributes.phone_number = this.phone_number }

    let labelCheck = false
    this.signUpFields.forEach(field => {
      if (field.label === this.getUsernameLabel()) {
        logger.debug(`Changing the username to the value of ${field.label}`)
        signup_info.username =
          signup_info.attributes[field.key] || signup_info.username
        labelCheck = true
      }
    })
    if (!labelCheck && !signup_info.username) {
      throw new Error(
        `Couldn't find the label: ${this.getUsernameLabel()}, in sign up fields according to usernameAttributes!`
      )
    }

    Auth.signUp(signup_info)
      .then((data) => {
        const portalId = '7112881'
        const formGuid = 'ff72ab39-ffa3-47a2-87d7-043e625de827'
        const hutk = Cookie.get('hubspotutk')

        fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fields: [
              {
                name: 'email',
                value: this.inputs.email
              },
              {
                name: 'firstname',
                value: this.inputs.name
              },
              {
                name: 'voce_vende_online_',
                value: document.getElementsByName('voce_vende_online_')[0].checked ? 'Sim' : 'Não'
              },
              {
                name: 'se_sim_qual_plataforma_ou_ferramenta_',
                value: plataform
              }
            ],
            context: {
              hutk: hutk,
              pageUri: `${process.env.REACT_APP_ADMIN_URL.replace('admin', 'signin')}?signUp`,
              pageName: 'Registre-se'
            }
          })
        })
        this.setState({ requestPending: false })
        this.changeState('confirmSignUp', data.user.username)
      }).catch(err => {
        this.setState({ requestPending: false })
        return this.error(err)
      })
  }

  showComponent (theme) {
    this.signUpFields = this.props.signUpConfig.signUpFields

    const SubmitForm = (e) => {
      e.preventDefault()
      const pwd = document.getElementsByName('password')[0].value
      const pwd_confirm = document.getElementsByName('confirm_password')[0]
        .value

      const el = document.getElementById('flashDiv')
      if (el) {
        el.remove()
      }

      if (pwd_confirm.length > 7 && pwd === pwd_confirm) {
        this.signUp(e)
      } else {
        const flashDiv = document.createElement('div')
        flashDiv.id = 'flashDiv'
        document.getElementsByTagName('body')[0].appendChild(flashDiv)
        flashDiv.innerHTML = 'Senhas estão diferentes'
        setTimeout(() => {
          const el = document.getElementById('flashDiv')
          if (el) el.remove()
        }, 1000 * 3)
      }
    }

    const Field = ({ field, ...props }) => {
      let columns = ''

      if (field.key === 'name') {
        columns = 'col-sm-12'
      } else {
        columns = 'col-sm-6'
      }

      return (
        <div className={`col ${columns}`}>
          {field.key !== 'phone_number' ? (
            <input
              autoFocus={
                this.signUpFields.findIndex((f) => {
                  return f.key === field.key
                }) === 0
              }
              placeholder={`${I18n.get(field.label)}${
                !field.required ? ' (opcional)' : ''
              }`}
              type={field.type}
              name={field.key}
              key={field.key}
              onChange={this.handleInputChange}
              className='input'
            />
          ) : (
            <PhoneField
              required={field.required}
              defaultDialCode={this.getDefaultDialCode()}
              className='input'
              label={field.label}
              placeholder={`${I18n.get(field.label)}${
                !field.required ? ' (opcional)' : ''
              }`}
              onChangeText={this.onPhoneNumberChanged}
              key='phone_number'
            />
          )}
        </div>
      )
    }

    return (
      <div className='form-signUp'>
        <div className='cardMiddle'>
          <div className='row'>
            <div className='col-6 image-align'>
              <div className='card-image'>
                <div className='col col-12 border-top'>
                  <div className='title-logo'>
                    <img
                      src={logo}
                      alt={settings.title_site}
                      className='img-fluid logo'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-6'>
              <div className='card-signup page-signUp'>
                <form>
                  <div className='row'>
                    {this.signUpFields.map((field, index) => (
                      <Field key={index} field={field} />
                    ))}

                    <div className='col col-sm-6'>
                      <input
                        placeholder={`${I18n.get('Confirm Password')}`}
                        type='password'
                        name='confirm_password'
                        key='confirm_password'
                        className='input'
                      />
                    </div>

                    <div className='col col-sm-6'>
                      <div className='label-title'>Você vende online?</div>
                      <label htmlFor='voce_vende_online_sim'>
                        <input
                          placeholder={`${I18n.get('Confirm Password')}`}
                          type='radio'
                          name='voce_vende_online_'
                          id='voce_vende_online_sim'
                          className='input_radio'
                        />
                        Sim
                      </label>
                      <label htmlFor='voce_vende_online_nao'>
                        <input
                          defaultChecked
                          placeholder={`${I18n.get('Confirm Password')}`}
                          type='radio'
                          name='voce_vende_online_'
                          id='voce_vende_online_nao'
                          className='input_radio'
                        />
                        Não
                      </label>
                      <div>
                        <div className='label-title'>Se sim, qual plataforma ou ferramenta?</div>
                        <select name='se_sim_qual_plataforma_ou_ferramenta_' defaultValue='Ainda não vendo online.'>
                          <option value='Loja Integrada'>Loja Integrada</option>
                          <option value='Tray'>Tray</option>
                          <option value='NuvemShop'>NuvemShop</option>
                          <option value='Shopify'>Shopify</option>
                          <option value='Dooca'>Dooca</option>
                          <option value='Mercado livre'>Mercado livre</option>
                          <option value='Outros marketplaces (B2W, Amazon, Magalu e etc)'>Outros marketplaces (B2W, Amazon, Magalu e etc)</option>
                          <option value='Ainda não vendo online.'>Ainda não vendo online.</option>
                        </select>
                      </div>
                    </div>

                    <div className='col-12 d-flex justify-content-center'>
                      <div className='col-6 center'>
                        <button
                          className='btn'
                          type='submit'
                          onClick={(e) => SubmitForm(e)}
                        >
                          {I18n.get('Create Account')}
                        </button>

                        <div className='text-center'>
                          <a onClick={() => super.changeState('signIn')}>
                            {I18n.get('Back to Sign In')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
