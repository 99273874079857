import React from 'react';
import config from '../../aws-exports';
import {
  Authenticator,
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  VerifyContact
} from 'aws-amplify-react';
import { academyLoginTheme, adminLoginTheme } from '../../theme';
import { signUpConfig, usernameAttributes } from '../../configs/signUpConfig';
import {CustomSignIn} from '../custom-pages/CustomSignIn';
import {CustomForgotPassword} from '../custom-pages/CustomForgotPassword';
import { SignUp as CustomSignUp } from '../custom-pages/SignUp';
import { CustomConfirmSignUp } from '../custom-pages/CustomConfirmSignUp';
import { getRedirectUrl, hasAdmin, createCookie, clearCookie} from '../../util/util';
import { BoxLogin } from './styles';
import { Auth } from 'aws-amplify';

export default class Authentication extends React.Component {
  constructor() {
    super();
    this.state = {
      authState: '',
      page: window.location.href.indexOf('signUp') > -1 ? 'signUp' : ''
    };
  }

  redirectToState = () => {
    switch (this.props.page) {
      case 'renew' :
        return 'forgotPassword';
      case 'signUp' :
        return 'signUp';
      default:
        return 'signIn'
    }
  };

  async isAuth() {
    try {
      const response = await Auth.currentSession();
      createCookie(response);

      this.setState({
        authState: 'signedIn'
      })
    }
    catch (e) {
      clearCookie();
      this.setState({
        authState: 'signOut'
      })
    }
  }

  onStateChange = async (authState) => {
    this.setState({ authState });
    await this.isAuth();

    if (authState === 'signedIn') {
      getRedirectUrl()
    }
  };

  render() {
    const { authState} = this.props;
    return (
      <div>
      <div className="banner-login"/>
        <BoxLogin className="col-md-8 my-5 col-lg-4">
          {authState !== 'signedIn' ? (
            <>
              <Authenticator
                authState={this.redirectToState()}
                amplifyConfig={config}
                onStateChange={this.onStateChange}
                usernameAttributes={usernameAttributes}
                theme={hasAdmin() ? adminLoginTheme : academyLoginTheme}
                hide={[
                  SignIn,
                  ConfirmSignIn,
                  RequireNewPassword,
                  SignUp,
                  ConfirmSignUp,
                  VerifyContact,
                  ForgotPassword,
                ]}
                hideDefault={true}
              >
                <CustomSignIn/>
                <ConfirmSignIn/>
                <RequireNewPassword/>
                <CustomSignUp override="SignUp" signUpConfig={signUpConfig}/>
                <CustomConfirmSignUp/>
                <VerifyContact/>
                <CustomForgotPassword/>
              </Authenticator>
            </>
          ) : (
            <div className="force-align-center">
              <div className="spinner"/>
            </div>
          )}
        </BoxLogin>

    </div>

    )
  }
}
