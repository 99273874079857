import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LogoStyle = styled.img`
   
`;
export const CadastarStyle = styled(Link)`
    color: #000;
    text-decoration: underline;
    font-weight: bold;
    font-size: 0.9em;
`;
export const EsqueceuStyle = styled(Link)`
    color: #000;
    text-decoration: underline;
    font-size: 0.9em;
    margin-top:0.5em;
    & b{
        font-weight: bold;
    }
`;
export const BoxLogin = styled.div`   
    & label{
        text-align: center;
        width: 100%;
    
    }
`;