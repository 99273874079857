/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { I18n } from 'aws-amplify'
import { ConfirmSignUp } from 'aws-amplify-react'
import logo from '../../assets/images/logo.png'
import { settings } from '../../configs/settings'
import Auth from '@aws-amplify/auth'
import { auth } from '../../../node_modules/aws-amplify-react/lib-esm/Amplify-UI/data-test-attributes'

export class CustomConfirmSignUp extends ConfirmSignUp {
  handleConfirmClick (param) {
    var _this = this

    var username = this.usernameFromAuthData() || this.inputs.username
    var code = this.inputs.code

    if (!Auth || typeof Auth.confirmSignUp !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported')
    }

    Auth.confirmSignUp(username, code).then(function () {
      sessionStorage.setItem('lmsDemoEmail', username)
      return _this.changeState('signedUp')
    }).catch(function (err) {
      return _this.error(err)
    })
  }

  showComponent () {
    const confirmSignUp = this.props.hide.indexOf(ConfirmSignUp)
    this.props.hide.splice(confirmSignUp, 1)

    return (
      <div className='cardMiddle'>
        <div className='row'>
          <div className='col-6 image-align'>
            <div className='card-image'>
              <div className='col col-12 border-top'>
                <div className='title-logo'>
                  <img
                    src={logo}
                    alt={settings.title_site}
                    className='img-fluid logo'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-6 card-forgotpassword'>

            <div className='row'>
              <div className='col col-8 offset-2'>
                <div className='ResetPassword text-left'>

                  <input
                    className='input center'
                    key='username'
                    name='username'
                    value={this.usernameFromAuthData() ? this.usernameFromAuthData() : ''}
                    onChange={this.handleInputChange}
                    data-test={auth.confirmSignUp.usernameInput}
                    type='email'
                    placeholder={I18n.get('Enter your email')}
                  />

                  <input
                    className='input center'
                    key='code'
                    name='code'
                    onChange={this.handleInputChange}
                    data-test={auth.confirmSignUp.confirmationCodeInput}
                    autoComplete='off'
                    autoFocus
                    placeholder={I18n.get('Confirmation Code')}
                  />

                  <div className='center'>
                    {I18n.get('Lost your code? ')}
                    <a onClick={() => this.resend}>
                      {I18n.get('Resend Code')}
                    </a>
                  </div>

                  <div className='center'>
                    <button
                      className='btn'
                      onClick={e => this.handleConfirmClick(this.confirm)}
                      data-test={auth.confirmSignUp.confirmButton}
                    >
                      {I18n.get('Submit')}
                    </button>
                  </div>

                  <div className='text-center'>
                    <a
                      className='mb-30'
                      onClick={() => super.changeState('signIn')}
                    >
                      {I18n.get('Back to Sign In')}
                    </a>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
};
